// Sibly Color System

/* Design system colors */

// Brand colors
$brand-primary: #fc1774;
$brand-secondary: #362E82;

// Main colors
$purple-10: #D8D6F4;
$purple-20: #B8B4E4;
$purple-30: #8077CF;
$purple-40: #4B40B5;
$purple-50: #322A79;
$purple-55: #2B2474;
$purple-60: #211B65;
$purple-80: #090069;
$flamingo: #FC53A8;
$mustard: #D3A967;
$seal: #636A8B;
$main-cloud: #DCE0F2;
$aqua: #23C2E9;

// Semantic colors
$success: #4cb100;
$warning: #C52620;
$link: #1264A3;

// Neutral colors
$neutral-purple-gray-5: #F7F8FC;
$neutral-purple-gray-10: #E8E7EC;
$neutral-purple-gray-15: #DCDBE0;
$neutral-purple-gray-20: #BFBFC6;
$neutral-purple-gray-40: #8C8B99;
$neutral-purple-gray-60: #575564;
$neutral-purple-gray-80: #353440;
$neutral-night: #151515;
$gandalf: #ffffff;

// Hover + Selection Colors
$left-nav-select: #ffffff26;
$left-nav-hover: #ffffff40;

/* Other colors */
$light-gray: #cccccc;
$gray: #999999;
$dark-gray: #333333;
$disabled: #6c757d;
$neutral-4: #DFDEDE;
$light-purple: #8177cf;
$medium-purple: #544d95;
$light-blue: #dce1f1;
$blue: #248ccb;
$magenta: #b246a8;
$light-pink: #f5429c;
$eggplant: #891245;
$red: #dc3545;
$red-error: #FB2424;
$yellow: #ffc107;
$yellow-orange: #FFB471;
$blue-alert-link: #1976BE;

// Override Bootstrap Theme Colors (primary, secondary, etc.)
$theme-colors: (
  // Design system colors
  "primary": $brand-primary,
  "secondary": $brand-secondary,
  "purple-10": $purple-10,
  "purple-20": $purple-20,
  "purple-30": $purple-30,
  "purple-40": $purple-40,
  "purple-50": $purple-50,
  "purple-55": $purple-55,
  "purple-60": $purple-60,
  "purple-80": $purple-80,
  "flamingo": $flamingo,
  "mustard": $mustard,
  "seal": $seal,
  "main-cloud": $main-cloud,
  "aqua": $aqua,
  "success": $success,
  "warning": $warning,
  "link": $link,
  "neutral-purple-gray-5": $neutral-purple-gray-5,
  "neutral-purple-gray-10": $neutral-purple-gray-10,
  "neutral-purple-gray-15": $neutral-purple-gray-15,
  "neutral-purple-gray-20": $neutral-purple-gray-20,
  "neutral-purple-gray-40": $neutral-purple-gray-40,
  "neutral-purple-gray-60": $neutral-purple-gray-60,
  "neutral-purple-gray-80": $neutral-purple-gray-80,
  "neutral-night": $neutral-night,
  "gandalf": $gandalf,
  "left-nav-select": $left-nav-select,
  "left-nav-hover": $left-nav-hover,
  // Other colors
  "gray": $gray,
  "light-gray": $light-gray,
  "dark-gray": $dark-gray,
  "disabled": $disabled,
  "light-blue": $light-blue,
  "light-purple": $light-purple,
  "medium-purple": $medium-purple,
  "light-pink": $light-pink,
  "magenta": $magenta,
  "yellow": $yellow,
  "red-error": $red-error,
  "blue-alert-link": $blue-alert-link,
  "white": $gandalf,
);
